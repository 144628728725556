import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import _ from 'lodash'

import BlogLayout from '../components/BlogLayout'
import PageHeader from '../components/styles/PageHeader';
import PageContainer from '../components/styles/PageContainer'
import BlogList1 from '../components/BlogList/BlogList1';
import TagNav from '../components/TagNav';
import { formatAirtableNode, formatAirtableSubMenu } from '../lib/utils';

const TagHeader = styled.div`
  max-width: 1127px !important;
  margin: auto;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  padding: 20px 10px 0;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    text-align: center;
  }
  text-transform: uppercase;
`;

const Content = styled.div`
  .list {
    min-height: 120px;
  }
`

class CatRoute extends React.Component {
  render() {
    const { data } = this.props;
    // const posts = this.props.data.allMarkdownRemark.edges
    const posts = _.castArray(_.get(this.props.data, 'posts.nodes', [])).map(formatAirtableNode);
    const submenus = formatAirtableSubMenu(_.get(data, 'submenus.nodes')) || _.get(data, 'submenuPage.frontmatter.menuItems');

    const category = this.props.pageContext.category
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.posts.totalCount
    return (
      <BlogLayout>
        <section className="section">
          <Helmet title={`${category} | ${title}`} />
          <PageContainer className="container">
            <Content className="content">
              <PageHeader>
                <a href="/">Blog</a>
              </PageHeader>
              <TagNav
                allTagsRemark={this.props.data.allTagsRemark}
                submenus={submenus}/>
              <TagHeader>
                {
                  `${totalCount} post${totalCount === 1 ? '' : 's'} tagged with`
                }
                <u style={{ marginLeft: '5px' }}>{category}</u>
              </TagHeader>
              <BlogList1 posts={posts} className="list" />
            </Content>
          </PageContainer>
        </section>
      </BlogLayout>
    )
  }
}

export default CatRoute

export const catPageQuery = graphql`
  query CatPage($catRegex: String) {
    site {
      siteMetadata {
        title
      }
    }
    posts: allAirtable(
      filter: {
        table: {in: ["Blog", "News"]},
        data: {
          category: {regex: $catRegex},
          status: {eq: "published"}
        }
      },
      sort: {
        order: DESC, fields: data___date_created
      }
    ) {
      totalCount
      nodes {
        ...blogFragment
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: {
        category: { regex: $catRegex }
      } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            tags
            category
          }
        }
      }
    }
    submenus: allAirtable(filter: {
      data: {Segment: {regex: "/^submenu/"}}
      table: {in: ["Website"]},
    }, sort: {fields: data___Segment}) {
      nodes {
        data {
          Segment
          Title
          SubTitle
          SubTitle2
        }
      }
    }
    submenuPage: markdownRemark(frontmatter: { templateKey: { eq: "submenu" } }) {
      id
      frontmatter {
        menuItems {
          label
          linkType
          linkURL
        }
      }
    }
    allTagsRemark: allMarkdownRemark(
      limit: 1000
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            tags
            category
          }
        }
      }
    }
  }
`
